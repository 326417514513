import { Component, Input } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() message: string;
  @Input() buttonsLabels: { ok: string; cancel?: string } = {
    ok: 'OK',
    cancel: 'Cancel',
  };

  constructor(protected dialogRef: NbDialogRef<boolean>) {}

  close(res) {
    this.dialogRef.close(res);
  }
}
