<nb-card style="width: 550px">
    <nb-card-body class="confirmation-dialog-body" [innerHTML]="message"> </nb-card-body>
    <nb-card-footer class="text-right">
        <button *ngIf="buttonsLabels.cancel" nbButton shape="round" outline status="primary" (click)="close(false)">
            {{ buttonsLabels.cancel }}
        </button>
        <button nbButton shape="round" status="primary" (click)="close(true)">
            {{ buttonsLabels.ok }}
        </button>
    </nb-card-footer>
</nb-card>
